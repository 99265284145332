'use client'

import { Fragment } from 'react'
import Link from 'next/link'
import { Popover, Transition } from '@headlessui/react'
import clsx from 'clsx'

import { Logo } from './Logo'
import { NavLink } from './NavLink'

function MobileNavLink({
  href,
  children,
  prefetch,
}: {
  href: string
  children: string
  prefetch?: boolean
}) {
  return (
    <Popover.Button
      as={Link}
      href={href}
      className="block w-full p-2"
      prefetch={prefetch === undefined ? true : prefetch}
    >
      {children}
    </Popover.Button>
  )
}

function MobileNavIcon({ open }: { open: boolean }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-white"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx(
          'origin-center transition',
          open && 'scale-90 opacity-0',
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx(
          'origin-center transition',
          !open && 'scale-90 opacity-0',
        )}
      />
    </svg>
  )
}

function MobileNavigation({
  loginLink,
  prefetchDashboard,
  signupLink,
}: {
  loginLink: string
  prefetchDashboard: boolean
  signupLink: string
}) {
  return (
    <Popover>
      <Popover.Button
        className="relative flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="z-50 absolute inset-x-0 top-full flex origin-top flex-col rounded-2xl bg-white p-4 mx-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
          >
            <MobileNavLink href="/chat">Explore</MobileNavLink>
            <MobileNavLink href="#features">Features</MobileNavLink>
            {/* <MobileNavLink href="#testimonials">Testimonials</MobileNavLink> */}
            <MobileNavLink href="#pricing">Pricing</MobileNavLink>
            {/* <MobileNavLink href="/changelog">Changelog</MobileNavLink> */}
            <MobileNavLink href="https://docs.scoutos.com">Docs</MobileNavLink>
            <MobileNavLink href="https://blog.scoutos.com">Blog</MobileNavLink>
            <hr className="m-2 border-slate-300/40" />
            <MobileNavLink href={loginLink} prefetch={prefetchDashboard}>
              Login
            </MobileNavLink>
            <MobileNavLink href={signupLink}>Get Access</MobileNavLink>
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  )
}

const LoginButton = ({
  href,
  prefetch,
}: {
  href: string
  prefetch: boolean
}) => {
  return (
    <Link className="btn_1" href={href} prefetch={prefetch}>
      Login
    </Link>
  )
}
const SignUpButton = ({ href }: { href: string }) => {
  return (
    <Link className="btn_2" href={href}>
      Get Access
    </Link>
  )
}

export function Header({
  isSignedIn,
  loginLink,
  signupLink,
}: {
  isSignedIn: boolean
  loginLink: string
  signupLink: string
}) {
  return (
    <header className="z-20 bg-transparent col-span-12 py-5 marketing_nav grid grid-cols-12 relative">
      <nav className="z-50 flex w-full justify-between xl:col-start-3 col-span-12 px-4 xl:px-2 xl:col-span-8 max-w-[1440px] mx-auto">
        <div className="flex items-center justify-center md:gap-x-6">
          <Link href="/" aria-label="Home">
            <Logo className="w-auto" />
          </Link>
          <div className="hidden md:flex md:gap-x-6">
            {/* <NavLink href="/chat">Explore</NavLink> */}
            <NavLink href="/#features">Features</NavLink>
            {/* <NavLink href="#testimonials">Testimonials</NavLink> */}
            <NavLink href="/#pricing">Pricing</NavLink>
            {/* <NavLink href="/changelog">Changelog</NavLink> */}
            <NavLink href="https://docs.scoutos.com">Docs</NavLink>
            <NavLink href="https://blog.scoutos.com">Blog</NavLink>
          </div>
        </div>
        <div className="flex items-center gap-x- md:gap-x-4">
          <div className="hidden md:block">
            <LoginButton prefetch={isSignedIn || false} href={loginLink} />
          </div>
          <div className="mr-4 md:mr-0">
            <SignUpButton href={signupLink} />
          </div>
          <div className="-mr-1 md:hidden">
            <MobileNavigation
              prefetchDashboard={isSignedIn || false}
              loginLink={loginLink}
              signupLink={signupLink}
            />
          </div>
        </div>
      </nav>
    </header>
  )
}
